<script>
  import { onMount, onDestroy } from 'svelte';
  import { conf, lang, spinner } from './stores.js'
  import { ucFirst, i18n, cdn, isArray, asArray, inArray, dateTimeFormat, getById, setRoute } from './h.js'
  import _toNumber from 'lodash/toNumber'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'

  export let type = 'table'
  export let title = ''
  export let refresh = false
  export let objs = []
  export let meta = {}
  export let page = 0
  export let payload = false
  export let ref = false
  export let cardFooterDate = false
  export let cardPolaroid = false
  export let cardShadow = false
  export let cardUp = false
  export let counter = false //add a counter i in table
  export let noHover = false //don't make hover style in table

  export let action = false

  export let fields = []
  export let sortBy = {}

  //if(!sortBy && fields?.[0].id) filter=filters?.[0].filter;

  export let onSelect = false
  export let onSelectRoute = false

  export let multiSelect = false
  export let selects = []

  $: if (payload) {
    objs = payload.data;
    meta = payload.meta;
  }

  $: spinIt(objs);

  let scrollContainer;
  let lastScrollTop = 0;
  function handleScroll() {
      if ( scrollContainer.scrollTop > lastScrollTop && scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 10)  {
      refresh(page)
    }
    lastScrollTop = scrollContainer.scrollTop <= 0 ? 0 : scrollContainer.scrollTop;
  }
  onMount(() => {
    scrollContainer.addEventListener('scroll', handleScroll);
  });

  onDestroy(() => {
    scrollContainer.removeEventListener('scroll', handleScroll);
  });



  function show(obj, field) {
    let value = obj[field.id]
    if (field.options) return ucFirst(i18n(getById(field.options, value)?.name || value))
    if (field.format && typeof field.format === 'function') return field.format(value, obj, field)
    if (field.format == 'i18n') return ucFirst(i18n(`${field?.i18nPrefix || ""}${value}`))
    if (inArray(field.format, ['epoch', 'long', 'medium', 'short', 'dmy', 'short', 'shortDt', 'dt', 'shortTime', 'shortTimeSecond', 'time', 'complete'])) return dateTimeFormat(value, field.format)
    if (field.format == 'badge') return value ? `<i class="fa-solid fa-badge-check fa-fw"></i>` : ""

    if (value === true) return `<i class="${$conf.faType} text-success fa-check fa-fw"></i>`
    if (value === false || value === undefined) return "-"

    if(field.format=='tags' && value) {
      let ret=""
      value.forEach(element => ret+=`<span class="d-block mb-1 ellipsis badge-tag badge bg-secondary me-1">${element}</span>`)
      return ret
    }

    if (typeof value == 'number') return value
    else return ucFirst(i18n(value))
  }

  let spin = false
  function spinIt() {
    spin = true
    setTimeout(() => {
      spin = false
    }, 400)
  }

  function sortCycle(field) {
    if (!field.nosort && field.cond!==false) {
      if (sortBy[field.id] === undefined) sortBy[field.id] = 1;
      else if (sortBy[field.id] === 1) sortBy[field.id] = -1;
      else {
        delete sortBy[field.id];
        sortBy = sortBy;
      }
    }
  }
  //on re-ordonne les colonnes en suivants les filtres
  $: fields = [...Object.keys(sortBy).map(key => fields.find(item => item.id === key)).filter(Boolean), ...fields.filter(item => !sortBy.hasOwnProperty(item.id))];

</script>

{#if title}
<div class="non-selectable fs-5 my-2">
  {meta?.total || asArray(objs).length} {i18n(title, $lang)}
  {#if refresh}<span on:click={()=>{refresh();}} title={i18n("g--reload")} class="pointer hover fs-5 my-2"><I {spin} arrow-rotate-right /></span>{/if}
</div>
{/if}

<div class="fsScroll fsScrollX" bind:this={scrollContainer}>
  {#if asArray(objs).length}

    {#if type=="card"}
    <div class="cards mt-2">
      {#each asArray(objs) as obj,i (obj)}
      <div on:click={()=>{if(onSelect) onSelect(obj.id); if(onSelectRoute) setRoute(`${onSelectRoute}/${obj.id}`)}}
        class="card"
        class:pointer={onSelect || onSelectRoute}
        class:polaroid={cardPolaroid}
        class:shadow={cardShadow}
        class:up={cardUp}
        ref={obj[ref]}
        >
        <div class="card-header fsContentHorizontal">
          <slot name="header" {obj} {i} />
        </div>
        <div class="card-body p-0 pb-1">
          <div class="container-lg">
            <slot name="body" {obj} {i} />
          </div>
        </div>
        <div class="card-footer xsmall d-flex justify-content-between">
          <slot name="footer" {obj} {i} />
          {#if cardFooterDate}<div>{dateTimeFormat(obj[cardFooterDate],'shortTime')}</div>{/if}
        </div>
      </div>
      {/each}
    </div>
    {/if}

    {#if type=="table"}
    <table class="table">
      <thead class="non-selectable">
        <tr>
          {#if multiSelect}<th scope="col" class="pointer hover" on:click={()=>{selects=selects.length?[]:objs?.map(obj => obj.id)}}>☐</th>{/if}
          {#if counter}<th scope="col">#</th>{/if}
          {#each fields as field, f}
          <th class="pointer" scope="col" on:click={()=>sortCycle(field)}><span class="ucFirst">
              <I18n id={field.title} />
            </span>
            {#if !field.nosort && field.cond!==false}
            {#if sortBy[field.id]==-1}<I sort-up solid />
            {:else if sortBy[field.id]==1}<I sort-down solid />
            {:else}<I sort />{/if}
            <!--span class="xsmall">{Object.keys(sortBy).indexOf(field.id)!=-1 ? `(${Object.keys(sortBy).indexOf(field.id)+1})` : ""}</span-->
            {/if}
          </th>
          {/each}
          {#if action}<th><I cog /></th>{/if}
        </tr>
      </thead>
      <tbody>
        {#each asArray(objs) as obj, i (obj)}
        <tr class:showHover={!noHover} class:noPointer={!onSelect && !onSelectRoute} on:click={()=>{if(onSelect) onSelect(obj.id); if(onSelectRoute) setRoute(`${onSelectRoute}/${obj.id}`)}}>
          {#if multiSelect}<th on:click|stopPropagation scope="col"><input type="checkbox" bind:group={selects} value={obj.id} /></th>{/if}
          {#if counter}<th scope="col">{i+1}</th>{/if}
          {#each fields as field, f}
          <th>{@html show(obj,field)}</th>
          {/each}
          {#if action}<th on:click={()=>action(obj.id)}>
            <slot name="action" {obj} {i} />
          </th>{/if}
        </tr>
        {/each}
      </tbody>
    </table>
    {/if}
  {/if}
</div>

<style>
  tbody tr th {
    white-space: nowrap;
    font-weight: normal;
  }

  tbody tr.showHover:hover * {
    background-color: var(--navbarBgColor);
    cursor: pointer;
  }

  tbody tr.showHover.noPointer:hover {
    cursor: default;
  }

  table thead {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 2px solid currentColor;
  }

  table>:not(:first-child) {
    /* black line under header (deleted in bootstrap 5.2 !)*/
    border-top: 2px solid currentColor;
  }


  .fsScroll {
    padding-top: 0;
  }
</style>