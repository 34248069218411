<script>
  import { route, conf, modal, user, userId, customer, customerId, vendorId, alert } from './stores.js'
  import { setRoute, ucFirst, daysLeft, u, clipBoard, logout, asArray, i18n, api, apiPost, apiDelete, dateTimeFormat, sleep, debounce } from './h.js'

  import Alert from './G_Alert.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import LineMenu from './G_LineMenu.svelte'
  import Iban from './G_Iban.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import Route from './G_Route.svelte'
  import Invoices from './G_Invoices.svelte'
  import Licence from './L_Licence.svelte'
  import PricingGrid from './G_PricingGrid.svelte'
  import Wrong from './G_Wrong.svelte'
  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  import LocalAccount from './L_Account.svelte'

  export let menu

  let localAccountMenus = []

  let menus = []
  $: menus = [
    { route: '/account/users', name: 'users', roles: ["customer_admin", "avendor_admin"] },
    { route: '/account/teams', name: 'teams', roles: ["customer_admin"] },
    ...localAccountMenus,
    { route: '/account/informations', name: 'informations', roles: ['customer_admin'] },
    { route: '/account/domains', name: 'domains', valid: $conf.account?.withDomains, roles: ['customer_admin'] },
    { route: '/account/logs', name: 'logs', valid: $conf.account?.withLogs, roles: ['customer_admin'] },
    { route: '/account/licence', name: 'licence', valid: $conf.account?.withLicence, roles: ['customer_admin'] },
    { route: '/account/billing', name: 'billing', valid: $conf.account?.withBilling, roles: ['customer_admin'] },
    { route: '/account/api', name: 'api', valid: $conf.account?.withApi, roles: ['customer_admin'] },
    { route: '/account/support', name: 'support' }
  ]

  export let supportCanChangeConf = false //a modifier

  let keyword = "", sortBy = { name: 1 }, filter = { valid: true }, tagsSelected = []

  let payload = {}, obj = {}, objs = [], _razc=0, page=1;
  const apiZ = async (reload, what, ...rest) => { const ret = await api(...rest); what === "payload" ? payload = ret || {}: what === "obj" ? obj = ret?.data || {} : what === "objs" ? objs = ret?.data  || []: null; };
  const reloadApiZ = ()=>{_razc++;}


  async function userSet(userSelected) {
    await sleep(200) //to let time to form to be modified
    try{
      let payload = await apiPost(`/customers/_customerId_/users`, userSelected || obj)
      if(user.id==$user.id) $user = { ...$user, ...payload.data }
      //$alert.success = payload.message;
      setRoute("/account/users")
      reloadApiZ()
    } catch(e){}
  }

  async function userDel(userSelected) {
    userSelected ||= obj;
    $modal = {
      cancel: true, title: `${i18n("Delete")}  ${userSelected.name}`, message: i18n("account--Delete member"), color: 'danger', dangerZone: userSelected.name, confirm: async () => {
        await apiDelete(`/customers/_customerId_/users/${userSelected.id}`)
        if(userSelected.id==$userId) $user = {}
        setRoute("/account/users")
        reloadApiZ()
      }
    }
  }



  function startSubscription(planId) {
    setRoute(`/account/licence/checkout/${planId}`)
  }

  async function setCustomer() {
    let ret = await api(`post`, `/customers/_customerId_`, obj).data
    $user.customers[$customerId].name = ret.name
    $user.customers[$customerId].workspace = ret.workspace
    $user = $user
    $alert.success = "Updated"
  }

  async function customerDel() {
    $modal = {
      cancel: true, title: `${i18n("g--Delete")}  ${obj.name}`, message: i18n("account--delete account confirm"), color: 'danger', dangerZone: 'yes', confirm: async () => {
        //let data=await api(`delete`, `/customers/_customerId_`)
        logout()
      }
    }
  }

  async function genApiKey() {
    $modal = {
      cancel: true, title: `${i18n("g--Regenerate")} apiKey`, message: i18n("account--invalidate api confirm"), color: 'warning', confirm: async () => {
        //obj.apiKey=await api(`get`, `/customers/_customerId_/genApiKey`)
      }
    }
  }



  async function teamAdd() {
    let licence = await api(`get`, `/customers/_customerId_/licence`).data
    if (!licence.available?.users) $modal = {
      cancel: true,
      title: i18n("licence--No more users"),
      message: i18n("licence--No more users message"),
      color: 'warning', actionLabel: i18n("licence--Upgrade"),
      action: async () => {
        setRoute("/account/licence")
      }, cancel: () => { }
    }
    else {
      obj = {}
      setRoute("/account/users/add")
    }
  }

  async function sendCredential(user) {
    if (!user) return
    let data = await api(`post`, `/auth/sendcredentials`, { authId: user.email, vendorId: $vendorId, asCustomerId: $customerId }).data
    if (data) $alert.success = `${i18n("account--Credential sent to")} ${user.email}`
  }


  async function checkDomain(domain) {
    //return await api(`get`, `/app/customers/domains/check/${domain}`).data
  }

  async function addDomain() {
    obj = {}
    setRoute("/account/domains/add")
  }

  async function setDomain() {
    //await api(`post`, `/app/customers/domains/set`, obj)
    //sleep(100)
    //$customer.domains=await api(`get`, `/app/customers/domains/list`).data
    setRoute("/account/domains")
  }

  async function deleteDomain(domain) {
    //await api(`delete`, `/app/customers/domains/delete/${domain}`)
    //sleep(100)
    //$customer.domains=await api(`get`,`/app/customers/domains/list`).data
    setRoute("/account/domains")
  }


  let objsLogs = [], kewordLogs, filterLogs = {}, sortByLogs = { _cD: -1 }
  let filtersLogs = [
    { filter: {}, title: 'g--All' },
    { filter: { action: 'set' }, title: 'g--Set' }
  ]
  let sortBysLogs = [
    { id: '_cD', title: 'g--By date', order: -1 },
    { id: 'userEmail', title: 'g--By userEmail' },
    { id: 'action', title: 'g--By action' },
    { id: 'ressourceType', title: 'g--By ressourceType' }
  ]
  let fieldsLogs = [
    { id: "_cD", title: "g--date", format: 'shortTimeSecond' },
    { id: "userEmail", title: "g--email" },
    { id: "action", title: "g--action" },
    { id: "context", title: "g--context" },
    { id: "result", title: "g--result" },
    { id: "ressourceType", title: "g--ressource Type" },
    { id: "ressourceId", title: "g--ressource Id" },
    { id: "ressourceName", title: "g--ressource Name" },
    { id: "ressourceCount", title: "g--ressource Count" }
  ]  
</script>


<div class="container-lg fsContent">

  <Title title={menu?.name} />
  <LineMenu {menus} />

  <LocalAccount bind:menus={localAccountMenus} />

  <Route path="/account/informations" fsContent requiredRoles={menus}>
    {#await apiZ(_razc, `obj`, `get`, `/customers/_customerId_`) then}
    <div class="fsScroll"> 
      <Form>
        <Input readonly bind:value={obj.shortId} class="col-sm-2" label="reference" icon required />
        <Input bind:value={obj.name} class="col-sm-4" label="company" icon="building" required />
        <Input bind:value={obj.taxId} class="col-sm-3" label="VAT number" icon="taxid" />
        <Input bind:value={obj.workspace} class="col-sm-3" label="workspace" icon="fa-shop" />
        <Input bind:value={obj.email} class="col-sm-6" type="email" label="email" icon />
        <Input bind:value={obj.phone} class="col-sm-6" type="tel" label="phone" icon />
        <Input bind:value={obj.address} class="col-sm-8" label="address" icon />
        <Input bind:value={obj.zip} class="col-sm-4" label="zip" icon />
        <Input bind:value={obj.city} class="col-sm-4" label="city" icon />
        <Input bind:value={obj.state} class="col-sm-4" label="state" icon />
        <Input bind:value={obj.country} class="col-sm-4" label="country" icon />
        <Input bind:value={obj.slogan} class="col-12" label="slogan" icon />

        {#if $conf.account?.withSponsorship}
        <Input class="col-12" readonly bind:value={obj.sponsorshipId} label="sponsorship id" icon="fa-hands-holding-diamond" required />
        {/if}

        {#if $conf.account?.withSupport}
        <Input set={"support"} bind:value={obj.supportName} class="col-sm-6" label="g--name" icon="name" required />
        <Input set={"support@mydomain.zzz"} bind:value={obj.supportEmail} type="email" class="col-sm-6" label="g--email" icon="email" required />
        {/if}

        {#if supportCanChangeConf}
        <Alert local={{noDismiss:true, warning:"account--Define a mail from"}} />
        <Input set={false} type="switch" class="col-sm-6" label=" " option="g--use my SMTP mail server" bind:value={obj.isSmtpServer} />

        {#if obj.isSmtpServer}
        <Input set={"smtp.mydomain.zzz"} bind:value={obj.smtpServer} type="fqdn" class="col-sm-4" label="g--server" icon="fa-server" required />
        <Input set={25} bind:value={obj.smtpPort} type="number" class="col-sm-2" label="g--port" icon="fa-plug" required />
        <Input set={"user"} bind:value={obj.smtpAccount} type="text" class="col-sm-6" label="g--account" icon="fa-user" />
        <Input set={"password"} bind:value={obj.smtpPassword} type="password" noPattern class="col-sm-6" label="g--password" icon="fa-key" />
        {/if}
        {/if}
        {#if $conf.account?.withDPO}
        <Alert local={{noDismiss:true, warning:"account--Define a DPO"}} />
        <Input bind:value={obj.dpoEmail} type="email" class="col-sm-6" label="Dpo email" icon="email" />
        <Input bind:value={obj.dpoName} class="col-sm-6" label="Dpo name" icon="name" />
        <Input bind:value={obj.dpoLink} type="url" class="col-sm-12" label="Dpo link" icon="link" />
        {/if}
        <Button validity action={setCustomer} actionModel="update" class="col-auto" />
        <!--Button action={customerDel} actionModel="delete" class="col-auto"/-->
      </Form>
    </div>
    {:catch}
    <Wrong />
    {/await}
  </Route>



  <Route path="/account/users/*" pathHide="/account/users/+" fsContent requiredRoles={menus}>
    <div class="fsContent">
      <FilterLine search bind:keyword createRoles={["customer_admin","customer_manager"]} createRoute="/account/users/edit" 
      bind:filter filters={[ { filter: { valid:true }, title:'g--Active' }, { filter: { valid:false }, title:'g--Inactive' }, { filter: {}, title:'g--All' }, { filter: {roles:'admin'}, title:'g--Admin' } ]} />
      <List onSelectRoute="/account/users/edit" refresh={reloadApiZ} title="g--users" {payload} bind:sortBy fields={[ {id:"name", title:"g--name"}, {id:"email", title:"g--email"}, {id:"valid", title:"g--active", nosort:true} ]} />
      {#await apiZ(_razc, `payload`, `get`, `/customers/_customerId_/users`, {sortBy, keyword, filter, pageSize: 10, page}, {debounce: true}) then}<!-- do not put List here to avoid infinite loop-->{:catch}
        <Wrong />
      {/await}
    </div>
  </Route>


  <Route path="/account/users/edit/:id?" let:routeParams fsContent>

    <div class="fsContent">
      <Title title detail={obj.name} back="/account/users" />
      {#await apiZ(false, `obj`, `get`, `/customers/_customerId_/users/${routeParams.id}`, {}, {dry: !routeParams.id ? {}:null }) then}
        <Form fsContent norow>
          <div class="row fsScroll">
            <Input disabled={obj.id} bind:value={obj.email} type="email" icon label="Email" placeholder="g--Email" required />
            <Input disabled={obj.id}  bind:value={obj.name} icon="user" label="Name" placeholder="g--Name" required />
            <Input lg disabled={$userId==obj.id} set={true} bind:value={obj.valid} type="switch" option="g--Active"/>
            
            {#if $conf.sign2FA}
              <Input set={false} lg type="switch" option="2FA" bind:value={obj.is2fa} />
            {/if}
    
            <div class="row">
              {#if obj.id}
                <Button validity  action={userDel} actionModel="delete" class="col-auto" />
                <Button validity  action={userSet} actionModel="update" class="col-auto" />
              {:else}
                <Button validity  action={userSet} actionModel="save" class="col-auto" />
              {/if}
            </div>
          </div>
        </Form>
      {:catch}
      <Wrong />
      {/await}
    </div>
  </Route>




  <Route path="/account/teams/*" pathHide="/account/teams/+" fsContent requiredRoles={menus}>
    {#await apiZ(_razc, `payload`, `get`, `/customers/_customerId_/teams`, null, {debounce: true}) then}
    <div class="fsContentHorizontal fs-5 my-1 ">
      <div>{payload.meta.count} <I18n>account--Teams</I18n>
      </div>
      <Button add={()=>{teamAdd()}} title="account--add team"/>
    </div>

    <div class="fsScroll">
      <div class="cards">
        {#each payload.data as user,i (user)}
        <div class="card shadow">
          <div class="card-header">
            <div class="fsContentHorizontal">
              <div class="fs-5 fw-bold">{u(user.name)}</div>
              {#if $userId==user.id}({i18n("Me")}){/if}
              <Input set={false} type="switch" disabled={$userId==user.id} option="g--Active" bind:value={user.valid} change={()=>userSet(user)}/>
            </div>
            <div class="small">{u(user.email)}</div>
          </div>
          <div class="card-body">
            <Input set={[]} type="switch" disabled={$userId==user.id} optionsDisabledAllOthersWhen="admin" options={$conf.roles.c} bind:value={user.roles} change={()=>userSet(user)}/>
          </div>
          {#if $userId!=user.id}
          <div class="card-footer fsContentHorizontal">
            {#if payload.meta.count}
            <Button del={()=>userDel(user)}/>
              {/if}
              <div class="pointer" on:click={()=>sendCredential(user)}><i class="{$conf.faType} fa-key pointer"></i>
                <I18n>Send credentials</I18n>
              </div>
          </div>
          {/if}
        </div>
        {/each}
      </div>
    </div>
    {:catch}
    <Wrong />
    {/await}
  </Route>


  <Route path="/account/teams/add" fsContent requiredRoles={menus}>
    <Title title="account--add team" cancel="/account/users" />
    <Form>
      <Input bind:value={obj.email} icon type="email" label="email" required />
      <Input bind:value={obj.name} icon type="text" label="name" />
      <Input set={[]} type="switch" optionsDisabledAllOthersWhen="admin" options={$conf.roles.c} bind:value={obj.roles} />
      <Button validity action={()=>userSet()} actionModel="add" class="col-auto"/>
    </Form>
  </Route>


  <Route path="/account/domains" fsContent requiredRoles={menus}>
    <div class="fsContentHorizontal fs-5">
      <div>{asArray($customer.domains).length} <I18n>account--Domains</I18n>
      </div>
      <Button add={()=>{addDomain()}} title="account--add domains"/>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">
            <I18n>account--Domain</I18n>
          </th>
          <th scope="col">
            <I18n>account--Status</I18n>
          </th>
        </tr>
      </thead>
      <tbody>
        {#each asArray($customer.domains) as domain,i (domain)}
        <tr>
          <th scope="row">{i+1}</th>
          <td>{domain.id}</td>
          <td>
            {#await checkDomain(domain.id) then check}
            {#if check.valid}
            <span class="badge bg-success">
              <I18n>account--Domain valid</I18n>
            </span>
            {:else}
            <span class="badge bg-danger">
              <I18n>account--Domain not yet transfered</I18n>
            </span>
            {/if}
            <button on:click={()=>deleteDomain(domain.id)} class="btn btn-xs btn-danger"><I trash /></button>
            {/await}
          </td>
        </tr>
        {/each}
      </tbody>
    </table>
  </Route>


  <Route path="/account/domains/add" fsContent requiredRoles={menus}>
    <Title title="account--add domain" cancel="/account/users" />
    <p>
      <I18n>account--To add your own domain</I18n>
    </p>
    <div class="p-3 bg-light">
      <div class="fsContentHorizontal">
        <div><code>{"your.domain.com" + ' CNAME ' + $conf.cname + '.'}</code> <button disable={!obj.id} class="btn btn-xs btn-outline-secondary" on:click={()=>clipBoard((obj.id ||"your.domain.com") +
            ' CNAME ' + $conf.cname, true)}><I18n>Copy</I18n></button></div>
      </div>
    </div>
    <Form>
      <Input placeholder="your.domain.com" bind:value={obj.domain} icon type="fqdn" label="account--domain" required />
      <Button validity action={setDomain} actionModel="add" class="col-auto" />
    </Form>
  </Route>


  <Route path="/account/api" fsContent requiredRoles={menus}>
    <I18n>account--Use api</I18n>
    <div class="p-3 bg-light">
      <div class="fsContentHorizontal">
        <div><code>{obj.apiKey}</code> <button class="btn btn-xs btn-outline-secondary" on:click={()=>clipBoard(obj.apiKey, true)}><I18n>Copy</I18n></button></div>
        <Button action={genApiKey} title="Regenerate" color="warning" icon="undo" />
      </div>
    </div>
  </Route>


  <Route path="/account/billing" fsContent requiredRoles={menus}>
    <div class="fsScroll">
      <!--Form>
          <Iban/>
        </Form-->
      {#await api(`get`, `/customers/_customerId_/invoices`).data then invoices}
      <Invoices {invoices} />
      {/await}
    </div>
  </Route>

  <Route path="/account/logs" fsContent requiredRoles={menus}>
    <FilterLine search bind:keyword={kewordLogs} bind:filter={filterLogs} filters={filtersLogs} bind:sortBy={sortByLogs} sortBys={sortBysLogs} />
    {#await api(`get`, `/customers/_customerId_/logs`,{keyword:kewordLogs, filter: filterLogs, sortBy: sortByLogs}) then payload}
    <List title="g--last logs" {payload} fields={fieldsLogs} />
    {/await}
  </Route>



  <Route path="/account/support" fsContent>
    <h5>
      <I18n>account--Your support</I18n>
    </h5>
    {#if $conf.support}
    <p>{u($conf.support.email)}</p>
    <p>{u($conf.support.phone)}</p>
    <p>{u($conf.support.text)}</p>
    {/if}
    <p>Version V{$conf.version} - /{$conf.htmlRoot} - {ucFirst($conf.serviceCode)} </p>
  </Route>




  <Route path="/account/licence" fsContent requiredRoles={menus}>
    <div class="card">
      <div class="card-header fw-bold">
        <I18n>licence--Your</I18n>
      </div>
      {#await apiZ(_razc, `obj`, `get`, `/customers/_customerId_/licence`).data then obj}
      <div class="card-body">
        {#if obj.expireDate}
        <div class="card-title">
          <I18n>licence--Expiration date</I18n>: {dateTimeFormat(obj.expireDate, 'complete')}
        </div>
        {#if obj.expired}
        <div class="h4 text-danger fw-bold">
          <I18n>licence--Expired</I18n>
        </div>
        {:else}
        <p class="card-text">
          <I18n>licence--Days left</I18n>: {daysLeft(obj.expireDate)}
        </p>
        {/if}
        {/if}
        {#if obj.expired}
        <button on:click={()=>setRoute('/account/licence/subscription')} class="btn btn-lg btn-warning"><I18n>licence--Renew</I18n></button>
        {/if}
        {#if obj.upgrade}
        <button on:click={()=>setRoute('/account/licence/subscription')} class="btn btn-lg btn-success"><I18n>licence--Upgrade</I18n></button>
        {/if}
        {#if $conf.account?.withLicenceDetail}
        <Licence {obj} select="accountCustomer" />
        {/if}
      </div>
      {/await}
    </div>
  </Route>




  <Route path="/account/licence/subscription" fsContent requiredRoles={menus}>
    <div class="fsScroll">
      <div class="container-lg">
        {#await api(`obj`, `get`, `/customers/_customerId_/pricing`).data then pricing}
        <PricingGrid CTA={startSubscription} {pricing} subscription compare={$conf.account?.withComparePlan} />
        {/await}
      </div>
    </div>
  </Route>


  <Route path="/account/licence/checkout/:planId" let:routeParams fsContent requiredRoles={menus}>
    <Alert local={{noDismiss:true, danger:"Le système de paiement est en cours de mise à jour. Contactez-nous pour faire un changement de licence. Vous pourrez régler par virement ou prélevement."}} />

    <a class="btn btn-success"
      href="mailto:support@eqrito.com?subject=Demande d'information tarifaire&body=Bonjour,%0D%0Aje souhaite éventuellement souscrire à votre service ou changer de pack.%0D%0APouvez-vous me contacter par mail et me donner plus d'information sur vos différent tarifs.%0D%0A%0D%0AMerci.%0D%0A%0D%0A====================================%0D%0A{$customerId}%0D%0A====================================%0D%0A">Envoyez un email de demande</a>

    <div class="fsScroll">
      <div class="container-lg">
        <!--<Form>
            {routeParams.planId}
            <Input bind:value={obj.name} class="col-sm-6" label="company" icon required/>
            <Input bind:value={obj.taxId} class="col-sm-4" label="VAT number" icon="taxid" required/>
            <Input bind:value={obj.email} class="col-sm-6" type="email" label="email" icon required/>
            <Input bind:value={obj.phone} class="col-sm-6" type="tel" label="phone" icon/>
            <Input bind:value={obj.address} class="col-sm-8" label="address" icon/>
            <Input bind:value={obj.zip} class="col-sm-4" label="zip" icon/>
            <Input bind:value={obj.city} class="col-sm-4" label="city" icon/>
            <Input bind:value={obj.state} class="col-sm-4" label="state" icon/>
            <Input bind:value={obj.country} class="col-sm-4" label="country" icon required/>
            <Button validity action={setCustomer} actionModel="update" class="col-auto"/>
          </Form>-->
      </div>
    </div>
  </Route>
</div>